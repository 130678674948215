<template>
    <div class="shop_main noMenu">
        <div class="shop_order_main">
            <userMenu type="6" />

            <div class="shop_order_list">
                <div class="shop_order_search">
                    <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="85px">
                        <el-form-item label="报价时间：" prop="date">
                            <el-date-picker
                                    v-model="listQuery.date"
                                    type="daterange"
                                    range-separator="~"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOptions">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="采购单：" prop="title">
                            <el-input v-model="listQuery.title" placeholder="请输入采购单关键字" @keyup.enter.native="toSearchList"></el-input>
                        </el-form-item>
                        <transition name="slide-right">
                            <el-form-item label="采购单编号：" v-show="isQueryShow" prop="code">
                                <el-input v-model="listQuery.code" placeholder="请输入采购单编号" @keyup.enter.native="toSearchList"></el-input>
                            </el-form-item>
                        </transition>

                        <el-form-item class="last">
                            <el-button type="primary" :loading="isSearchLoading" @click="toSearchList">查 询</el-button>
                            <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                            <el-button type="text" @click="isQueryShow = false" v-if="isQueryShow">收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
                            <el-button type="text" @click="isQueryShow = true" v-else>展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="supplyBuyingQuotationList">
                    <div class="head">
                        <div class="item" style="flex: 1">
                            <span class="label">采购单信息</span>
                        </div>
                        <div class="item" style="width: 200px">
                            <span class="label">我的报价单</span>
                        </div>
                        <div class="item" style="width: 150px">
                            <span class="label">报价时间</span>
                        </div>
                        <div class="item" style="width: 70px">
                            <span class="label">操作</span>
                        </div>
                    </div>

                    <div class="list" v-for="(item, index) in listData" :key="index">
                        <div class="top">
                            <span class="sn">采购单编号：{{item.code}}</span>
                            <div class="goods_shop_name">
                                <img src="../../../../assets/img/shop_order.png" />
                                <span class="text">{{item.companyName}}</span>
                            </div>
                            <span class="sn"></span>
                        </div>
                        <div class="info">
                            <div class="item" style="flex: 1">
                                <span class="name">{{item.title}}</span>
                                <span class="text">{{item.goodName}} / ￥ {{item.price}}（{{getPriceStatus(item.priceStatus)}}） / {{item.num}}{{item.unitName}} {{item.sku}}</span>
                            </div>
                            <div class="item" style="width: 200px">
                                <span class="price">¥ {{item.qprice}}</span>
                                <span class="desc" :title="item.content">{{item.qcontent}}</span>
                            </div>
                            <div class="item" style="width: 150px">
                                <span class="time">{{item.createdAt}}</span>
                            </div>
                            <div class="item" style="width: 70px">
                                <el-button @click="$router.push({path: '/shop/supplyBuying/quotation/detail', query: {id: item.qid}})">详情</el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <noDataView v-if="listData.length === 0"/>

                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getMyOffer} from '../../../../api/shop/supplyBuying'
  import {imageURL} from "../../../../utils/config";
  const pagination = () => import('../../../../components/pagination/shop')
  const userMenu = () => import('../../../../components/layout/shop/userMenu')
  const noDataView = () => import('../../../../components/noDataView')
  export default {
    name: "shopSupplyBuyingQuotation",
    data () {
      return {
        listQuery: {
          title: '',
          code: '',
          date: [],
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        listData: [],
        isRequestLoading: false,
        isSearchLoading: false,
        imageURL: imageURL,
        isQueryShow: false,
        dateOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
      if (this.listKeepAlive.isQuerySelect) {
        //
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        let start = ''
        let end = ''
        if (this.listQuery.date.length > 0) {
          start = this.listQuery.date[0] + ' 00:00:00'
          end = this.listQuery.date[1] + ' 23:59:59'
        }
        getMyOffer({
          title: this.listQuery.title,
          content: this.listQuery.title,
          goodsName: this.listQuery.title,
          code: this.listQuery.code,
          start: start,
          end: end,
          page: this.listQuery.page,
          pageSize: this.listQuery.pageSize
        }).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
    },
    components: {
      pagination, userMenu, noDataView
    }
  }
</script>
